export const parseStampTime = stamp => {
  if (stamp === undefined) {
    return ''
  } else {
    const D = new Date(stamp * 1000)
    return `${D.getFullYear()}/${D.getMonth() + 1}/${D.getDate()}`
  }
}

export const stampToUTCtime = stamp => {
  return new Date(stamp * 1000)
}

export const timeToStamp = time => {
  return Date.parse(time.toString()) / 1000
}

// 只含月-日
export const getSubTine = time => {
  const D = new Date(time * 1000)
  return `${D.getFullYear()}-${D.getMonth() + 1 < 10 ? '0' + (D.getMonth() + 1) : D.getMonth() + 1}`
}
