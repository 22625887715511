import dynamic from 'next/dynamic'
import NavBar from './NavBar/NavBar'

export default function navbars(props) {
  // 默认为1模板
  // 其他模板动态导入
  const NavBar1 = dynamic(() => import('./NavBar/NavBar1'))
  const NavBar2 = dynamic(() => import('./NavBar/NavBar2'))
  const NavBar3 = dynamic(() => import('./NavBar/NavBar3'))
  const NavBar4 = dynamic(() => import('./NavBar/NavBar4'))
  const NavBar7 = dynamic(() => import('./NavBar/NavBar7'))
  const NavBar8 = dynamic(() => import('./NavBar/NavBar8'))
  const NavBar9 = dynamic(() => import('./NavBar/NavBar9'))
  const NavBar10 = dynamic(() => import('./NavBar/NavBar10'))
  const NavBar11 = dynamic(() => import('./NavBar/NavBar11'))
  const NavBar12 = dynamic(() => import('./NavBar/NavBar12'))
  const NavBar13 = dynamic(() => import('./NavBar/NavBar13'))
  const NavBar14 = dynamic(() => import('./NavBar/NavBar14'))
  const NavBar15 = dynamic(() => import('./NavBar/NavBar15'))
  const NavBar16 = dynamic(() => import('./NavBar/NavBar16'))
  const NavBar17 = dynamic(() => import('./NavBar/NavBar17'))
  const NavBar18 = dynamic(() => import('./NavBar/NavBar18'))
  const NavBar19 = dynamic(() => import('./NavBar/NavBar19'))
  const NavBar20 = dynamic(() => import('./NavBar/NavBar20'))
  const NavBar21 = dynamic(() => import('./NavBar/NavBar21'))
  const NavBar22 = dynamic(() => import('./NavBar/NavBar22'))
  const NavBar23 = dynamic(() => import('./NavBar/NavBar23'))
  const NavBar24 = dynamic(() => import('./NavBar/NavBar24'))
  const NavBar26 = dynamic(() => import('./NavBar/NavBar26'))
  const NavBar27 = dynamic(() => import('./NavBar/NavBar27'))
  const NavBar28 = dynamic(() => import('./NavBar/NavBar28'))
  const NavBar29 = dynamic(() => import('./NavBar/NavBar29'))
  const NavBar30 = dynamic(() => import('./NavBar/NavBar30'))
  const NavBar31 = dynamic(() => import('./NavBar/NavBar31'))
  const NavBar32 = dynamic(() => import('./NavBar/NavBar32'))
  const NavBar33 = dynamic(() => import('./NavBar/NavBar33'))
  const NavBar34 = dynamic(() => import('./NavBar/NavBar34'))
  const NavBar35 = dynamic(() => import('./NavBar/NavBar35'))
  const NavBar36 = dynamic(() => import('./NavBar/NavBar36'))
  const NavBar37 = dynamic(() => import('./NavBar/NavBar37'))

  return (
    <>
      {props.newsInfo.template_id === 1 && (
        <NavBar
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 2 && (
        <NavBar1
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 3 && (
        <NavBar2
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 4 && (
        <NavBar3
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 5 && (
        <NavBar4
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 7 && (
        <NavBar7
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 8 && (
        <NavBar8
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 9 && (
        <NavBar9
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 10 && (
        <NavBar10
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 11 && (
        <NavBar11
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 12 && (
        <NavBar12
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 13 && (
        <NavBar13
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 14 && (
        <NavBar14
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 15 && (
        <NavBar15
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 16 && (
        <NavBar16
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 17 && (
        <NavBar17
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 18 && (
        <NavBar18
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 19 && (
        <NavBar19
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 20 && (
        <NavBar20
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 21 && (
        <NavBar21
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 22 && (
        <NavBar22
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 23 && (
        <NavBar23
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 24 && (
        <NavBar24
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {/* 此导航栏已废弃 */}
      {/* {props.newsInfo.template_id === 25 && (
        <NavBar25
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          
          hot={props.hot}
        />
      )} */}
      {props.newsInfo.template_id === 26 && (
        <NavBar26
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 27 && (
        <NavBar27
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 28 && (
        <NavBar28
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 29 && (
        <NavBar29
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 30 && (
        <NavBar30
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 31 && (
        <NavBar31
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 32 && (
        <NavBar32
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 33 && (
        <NavBar33
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 34 && (
        <NavBar34
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 35 && (
        <NavBar35
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {props.newsInfo.template_id === 36 && (
        <NavBar36
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
      {/* 此导航栏为人力资源网正式模板导航不需要注入广告图片 */}
      {props.newsInfo.template_id === 25 && (
        <NavBar37
          columu={props.columu}
          newsInfo={props.newsInfo}
          curColumn={props.curColumn}
          width={props.width}
          headLogo={props.headLogo}
          news_id={props.news_id}
          hot={props.hot}
        />
      )}
    </>
  )
}
