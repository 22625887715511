import { useState, useEffect } from 'react'
import styles from '../../styles/NavBar/NavBar.module.css'
import commonStyles from '@/styles/common.module.css'
import Router from 'next/router'
import { IMGHOST } from '@/utils/ENV'
import Crypto from '../../utils/crypto'
import Image from 'next/image'

const NavBar = props => {
  // 更多按钮点击状态
  const [clickStatus, setClickStatus] = useState({ value: undefined })
  // 栏目截断
  const [startValue, setStartValue] = useState(13)
  // 更多列表高度
  const [moreBoxHeight, setMoreBoxHeight] = useState(0)

  // 点击更多或收回
  const getMoreList = () => {
    let data = { ...clickStatus }
    if (data.value === undefined) {
      data.value = false
    }
    setClickStatus({ value: !data.value })
    if (!data.value === true) {
      let len = props.columu.slice(startValue).length
      let num = 0
      if (props.width >= 1100) {
        num = parseInt(len / 7)
        if (len % 7 !== 0) {
          num = num + 1
        }
      } else {
        num = parseInt(len / 5)
        if (len % 5 !== 0) {
          num = num + 1
        }
      }
      setMoreBoxHeight((num + 1) * 0.3)
    } else {
      setMoreBoxHeight(0)
    }
  }

  const sureUrl = (articleId, newsId) => {
    let data = Crypto.Encrypt(JSON.stringify({ articleId, newsId }))
    Router.push(
      {
        pathname: '/column_article',
        query: {
          data,
        },
      },
      undefined,
      { shallow: true }
    )
  }
  /* 动态添加类名 start */
  const [activeType, setActiveType] = useState(undefined)
  const changeActiveType = item => {
    setActiveType(item.id)
  }

  useEffect(() => {
    if (props.width !== 0) {
      setStartValue(props.width >= 1100 ? 13 : 9)
    }
  }, [props.width])

  return (
    /* 导航栏的整体容器 */
    <div className={commonStyles.navBar_body} id="navBar_body">
      {props.headLogo.length !== 0 && (
        <Image width={20} height={20}
          className={commonStyles.logo_top_img}
          src={IMGHOST + props.headLogo[0].img}
          alt={props.headLogo[0].title}
          onClick={() => {
            window.open(`${props.headLogo[0].link}`)
          }} />
      )}
      <div className={styles.nav_bar_container}>
        {/* 导航栏内容容器(除去padding后的content) */}
        <div className={styles.nav_bar_content_container}>
          <div className={styles.news_name}>
            <Image
              width={20}
              height={20}
              src={IMGHOST + props.newsInfo.logo}
              alt={props.newsInfo.name}
              onClick={() => {
                if (Router.pathname === '/') return
                if (location.href.indexOf('localhost') > -1) {
                  Router.push(`/?news_id=${props.news_id}`)
                } else {
                  Router.push('/')
                }
              }}
            />
          </div>
          {/* 栏目数组的容器 */}
          {props.columu.length !== 0 && (
            <div className={styles.column_list_container}>
              {props.columu.slice(0, startValue)?.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className={`${styles.column_item}
                      ${activeType === undefined
                        ? parseInt(props.curColumn) === item.id
                          ? styles.active
                          : ''
                        : activeType === item.id
                          ? styles.active
                          : ''
                      }
                      `}
                    data-index={item.id}
                    onClick={() => {
                      if (activeType === undefined) {
                        if (parseInt(props.curColumn) === item.id) return
                      } else {
                        if (activeType === item.id) return
                      }
                      changeActiveType(item)
                      sureUrl(item.id, props.news_id)
                    }}
                  >
                    {item.name}
                    <div className={styles.column_item_bottom_line}></div>
                  </div>
                )
              })}
              {props.columu.length > startValue && (
                <div
                  className={commonStyles.all_center}
                  onClick={() => {
                    getMoreList()
                  }}
                >
                  <i
                    className="iconfont icon-gengduo1"
                    style={{ fontSize: '0.15rem', color: '#000' }}
                  ></i>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.more_columu_list} style={{ height: `${moreBoxHeight}rem` }}>
        <div
          className={styles.more_columu_box}
          style={{
            opacity: clickStatus.value === true ? 1 : 0,
            visibility: clickStatus.value === true ? 'visible' : 'hidden',
          }}
        >
          {props.columu.slice(startValue)?.map(item => {
            return (
              <div
                key={item.id}
                className={`${styles.more_columu_box_item} ${activeType === undefined
                  ? parseInt(props.curColumn) === item.id
                    ? styles.active
                    : ''
                  : activeType === item.id
                    ? styles.active
                    : ''
                  }`}
                data-index={item.id}
                onClick={() => {
                  if (activeType === undefined) {
                    if (parseInt(props.curColumn) === item.id) return
                  } else {
                    if (activeType === item.id) return
                  }
                  changeActiveType(item)
                  sureUrl(item.id, props.news_id)
                }}
              >
                {item.name}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default NavBar
